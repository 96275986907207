import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { CaseSlider } from 'ui/components/3-organisms/CaseSlider/CaseSlider';
import { GLOBAL_THEME_DEFAULT } from '_constants';

// Contexts
import { useSiteContext } from 'application/contexts/SiteContext';

export const CaseSliderFeature: React.FC<Content.CaseSlider> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const SiteContext = useSiteContext();
	const globalTheme: string = SiteContext.settings.globalTheme?.setTheme ?? GLOBAL_THEME_DEFAULT;

	const seeAllLinkLabel = dictionary.getValue('Link.SeeAllLink');
	const navigationPrevLabel = dictionary.getValue('Navigation.Prev');
	const navigationNextLabel = dictionary.getValue('Navigation.Next');

	return (
		<CaseSlider
			slides={content?.properties?.slides.map(({ content: { properties } }) => ({
				heading: properties.heading,
				bgColor: properties.color,
				kicker: {
					firstWord: properties.kickerFirstWord,
					secondWord: properties.kickerSecondWord,
				},
				seeAllLink: {
					href: properties.seeAllLink?.url,
					target: properties.seeAllLink?.target,
					title: properties.seeAllLink?.name,
				},
				caseLink: {
					href: properties.caseLink?.url,
					target: properties.caseLink?.target,
					title: properties.caseLink?.name,
				},
				image: {
					url: properties.image?.url,
					altText: properties.image?.properties?.altText,
				},
				seeAllLinkLabel: seeAllLinkLabel,
				navigationPrevLabel: navigationPrevLabel,
				navigationNextLabel: navigationNextLabel,
				globalTheme: globalTheme,
			}))}
		/>
	);
};
